import { FC, FormEvent, useEffect, useState } from 'react';

import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { QUERY_KEYS, useItem } from 'api/queries';
import images from 'assets/images';
import AlertModal from 'components/modals/AlertModal';
import Button from 'components/primitives/Button';
import CountDownTimer from 'components/primitives/CountDownTimer';
import { routesSettings } from 'constants/routes';
import ModalReference from 'helperClasses/modalReference';
import { useNavigate } from 'react-router-dom';
import { useRentItemStore } from 'state/atoms';
import { useHeaderContext } from 'state/contexts/headerContext';
import { $itemRentDetails } from 'state/stores/rent-details';
import { $setting } from 'state/stores/settings';
import { convertUTCToLocalTime } from 'utils/date';
import { calculatePrice } from 'utils/product';
import ToastHelper from 'utils/toast';
import { PaymentViewProps } from './Payment.props';

const PaymentView: FC<PaymentViewProps> = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const cancelModalReference = new ModalReference();
  const paymentFailedModalReference = new ModalReference();

  const [{ itemId, startDate, endDate }] = useRentItemStore();
  const [isLoading, setIsLoading] = useState(false);

  const { hideMainHeader } = useHeaderContext();

  const product = useItem(itemId!);

  const startDateTime = new Date(startDate!);
  const endDateTime = new Date(endDate!);

  const { tax, actualRentalPrice, totalPrice, daysDifference } = calculatePrice(
    product?.data?.price!,
    startDateTime,
    endDateTime,
  );

  const maxDurationInMinutes = 5;

  const orderCreationDateTime = $itemRentDetails.actions.getRentCreationDate();
  const orderCreationDate = convertUTCToLocalTime(orderCreationDateTime!);

  const date = new Date(orderCreationDate);
  const currentDate = new Date();

  // Calculate difference in seconds
  const differenceInSeconds = Math.floor(
    (currentDate.getTime() - date.getTime()) / 1000,
  );

  // Check if the difference exceeds the maximum allowed duration in seconds
  const [isExpired, setIsExpired] = useState(
    differenceInSeconds > maxDurationInMinutes * 60,
  );

  useEffect(() => {
    hideMainHeader();
  }, []);

  const cancelPayment = () => {
    navigate(-2);
  };

  const goBackToSummary = () => {
    navigate(-1);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const returnUrl = `${window.location.origin}`;

    setIsLoading(true);

    const stripeResult = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: returnUrl,
      },
    });

    setIsLoading(false); // Set loading to false here since this is always executed

    if (stripeResult.error) {
      ToastHelper.error(stripeResult.error.message!);
    } else {
      ToastHelper.success('Your order has been placed successfully!');
      setTimeout(() => {
        $setting.actions.unhideHeader();
        QUERY_KEYS.invalidate(QUERY_KEYS.FETCH_RENT_HISTORY_KEY);
        QUERY_KEYS.invalidate(QUERY_KEYS.FETCH_RENT_PAYMENT_DETAILS_KEY);
        navigate(routesSettings.MAIN_RENT_PRODUCT.getPath(itemId!), {
          replace: true,
        });
      }, 3000); // Use setTimeout for one-time delay
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {isExpired ? (
          <div className="flex flex-col justify-center items-center h-[80vh] space-y-6 m-10 md:px-60 px-4">
            <h2>Payment Failed</h2>
            <p>
              The rental payment window has closed. Please initiate a new
              booking.
            </p>
            <Button
              type="button"
              onClick={goBackToSummary}
              isLoading={isLoading}
              variant={'solid'}
              colorScheme={'primary'}>
              Go Back
            </Button>
          </div>
        ) : (
          <div className="flex flex-col justify-center h-[80vh] space-y-6 m-10 md:px-60 px-4">
            <div className="flex border rounded-md p-4 flex-col gap-4">
              <h3>Order Summary</h3>
              <table className="lg:w-[50%] ">
                <tbody>
                  <tr>
                    <td className="font-semibold">Start Date</td>
                    <td>{startDateTime.toDateString()}</td>
                  </tr>
                  <tr>
                    <td className="font-semibold">End Date</td>
                    <td>{endDateTime.toDateString()}</td>
                  </tr>

                  <tr>
                    <td className="font-semibold">Total</td>
                    <td>${totalPrice}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <PaymentElement
              options={{
                business: {
                  name: 'Rapid Rentals',
                },
              }}
            />

            <div className="flex flex-col justify-center items-center gap-4">
              <div className="flex gap-2">
                <p className="text-sm">
                  All payment information is securely processed by{' '}
                </p>
                <img width={40} height="auto" src={images.stripeLogo} alt="" />
              </div>
              <CountDownTimer
                onComplete={() => setIsExpired(true)}
                fromDate={orderCreationDateTime!}
                durationInMinutes={maxDurationInMinutes}
              />
            </div>
            <div className="flex justify-center space-x-4">
              <Button
                type="button"
                onClick={() => cancelModalReference.open()}
                variant="outline"
                colorScheme={'red'}>
                Cancel
              </Button>
              <Button
                isLoading={isLoading}
                variant={'solid'}
                colorScheme={'primary'}>
                Submit
              </Button>
            </div>
          </div>
        )}
      </form>
      <AlertModal
        ref={cancelModalReference.getReference()}
        buttonTitle="Yes"
        description="Are you sure you want to cancel this payment"
        onClickButton={cancelPayment}
      />
    </div>
  );
};

export default PaymentView;
