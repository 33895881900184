import { FC, useState } from 'react';

import PassworkValidatingStatus from 'components/form/PassworkValidatingStatus';
import AuthWrapper from 'components/modules/AuthWrapper';
import Button from 'components/primitives/Button';
import PasswordInput from 'components/primitives/PasswordInput';
import SelectInput from 'components/primitives/SelectInput';
import SelectOption from 'components/primitives/SelectOption';
import TextInput from 'components/primitives/TextInput';
import { Form, Formik } from 'formik';
import { isDev } from 'utils/environment';
import { SignupViewProps } from './Signup.props';
import { initialValues, validationSchema } from './Signup.utils';

type CountryCode = {
  title: string;
  code: string;
};

const productionCountryCodes: CountryCode[] = [{ title: 'US', code: '+1' }];

const stagingCountryCodes = [
  { title: 'IN', code: '+91' },
  { title: 'ES', code: '+34' },
  { title: 'UY', code: '+598' },
  { title: 'AR', code: '+54' },
];

const SignupView: FC<SignupViewProps> = ({
  onSubmit,
  goToLogin,
  isLoading,
}) => {
  const [showPasswordStatus, setShowPasswordStatus] = useState<boolean>(false);
  const devCountryCodes = isDev ? stagingCountryCodes : [];
  const countryCodes = [...productionCountryCodes, ...devCountryCodes];

  const getTitleFromCode = (code: string) => {
    return countryCodes.find((country) => country.code === code)?.title;
  };

  return (
    <AuthWrapper>
      <h2 className="text-center">Create your account</h2>
      <div className="justify-center items-center place-items-center m-4">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}>
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <div className="flex flex-col justify-center ">
                  <div className="flex gap-5">
                    <TextInput name="fname" placeholder="First Name" />
                    <TextInput name="lname" placeholder="Last Name" />
                  </div>

                  <TextInput type="email" name="email" placeholder="Email" />

                  <PasswordInput
                    name="password"
                    placeholder="Password"
                    hideErrorComponent={true}
                    onKeyUp={() => setShowPasswordStatus(true)}
                  />

                  <PassworkValidatingStatus hide={showPasswordStatus} />

                  <PasswordInput
                    name="confirmPassword"
                    placeholder="Repeat Password"
                  />

                  <div className="flex gap-5">
                    {isDev && (
                      <div className="w-[120px]">
                        <SelectInput
                          name="countryCode"
                          onChange={(value) => {
                            const title = getTitleFromCode(value);
                            setFieldValue('countrySymbol', title);
                          }}>
                          {countryCodes.map((item) => (
                            <SelectOption
                              value={item.code}
                              child={item.title}
                            />
                          ))}
                        </SelectInput>
                      </div>
                    )}

                    <TextInput type="tel" name="phone" placeholder="Phone" />
                  </div>

                  <p className="text-sm text-slate-500 ">
                    By entering my mobile number, I agree to receive important
                    account related texts from Rapid Rentals such as rental
                    confirmations and reminders. Message frequency varies by
                    account usage. Message & data rates may apply. Reply STOP to
                    opt out.
                  </p>

                  <Button
                    id="open-btn"
                    type="submit"
                    variant="solid"
                    isLoading={isLoading}
                    colorScheme="primary"
                    className="btn-var-solid w-full h-auto btn-var-solid mt-5">
                    Create Account
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {/* <div className="divider divider-neutral text-gray-600">or</div>
      <GoogleButton buttonText="Sign up with Google" /> */}
      <div className="flex flex-row justify-center mt-5 text-gray-600">
        <p className="text-sm">Have an account?&nbsp;</p>
        <p
          onClick={goToLogin}
          className="text-sm text-primary-500 cursor-pointer">
          Log into your account
        </p>
      </div>
    </AuthWrapper>
  );
};

export default SignupView;
