import { FC } from 'react';

import images from 'assets/images';
import CheckCircleIcon from 'components/icons/CheckCircleIcon';
import ErrorIcon from 'components/icons/ErrorIcon';
import CommonContainer from 'components/primitives/CommonContainer';
import Loading from 'components/primitives/Loading';
import { ProfileStripeSectionViewProps } from './ProfileStripeSection.props';

const ProfileStripeSectionView: FC<ProfileStripeSectionViewProps> = ({
  isFetching,
  isConnected,
  onClickChangeInformation,
  onClickConnect,
}) => {
  return (
    <CommonContainer>
      {isFetching ? (
        <Loading />
      ) : isConnected ? (
        <div className="w-full flex flex-col gap-8 md:flex-row justify-between">
          <div className="w-full flex flex-col gap-6 mb-2">
            <div className="flex items-center gap-4 mb-2">
              <h1>Stripe Connect</h1>
              <CheckCircleIcon />
            </div>
            <span>
              Stripe is connected. Click the button to update your payment
              information or manage your account.
            </span>
          </div>
          <button
            onClick={onClickChangeInformation}
            className="btn btn-primary text-white  capitalize font-bold">
            Go to
            <img width={60} src={images.stripeLogoWhite} alt="" />
            Dashboard
            {/* Change<span className="font-bold">Stripe</span>Information */}
          </button>
        </div>
      ) : (
        <div>
          <div className="w-full flex flex-col mb-2">
            <div className="flex items-center gap-4 mb-2">
              <h1>Stripe Connect</h1>
              <ErrorIcon />
            </div>
            <span>
              Looking to earn extra $$? Connect and verify your identity with
              Stripe to be able to list items and receive payments
            </span>
          </div>
          <button
            onClick={onClickConnect}
            className="btn btn-var-stripe  capitalize">
            Connect to
            <img width={60} src={images.stripeLogoWhite} alt="" />
            Account
          </button>
        </div>
      )}
    </CommonContainer>
  );
};

export default ProfileStripeSectionView;
